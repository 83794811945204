import React, { useState } from 'react';
import { Grid, Typography, Paper, styled } from '@mui/material';
import { ArrowBack, Timer } from '@mui/icons-material';
import CommunityMonthlyCount from './CommunityMonthlyCount.jsx';

const items = [
    { name: 'Community Posts Count', component: <CommunityMonthlyCount />, icon: <Timer /> },
].sort((a, b) => a.name.localeCompare(b.name));

const StyledPaper = styled(Paper)(({ theme }) => ({
    padding: '16px',
    textAlign: 'center',
    transition: 'transform 0.3s, box-shadow 0.3s',
    '&:hover': {
        transform: 'translateY(-4px)',
        boxShadow: theme.shadows[1],
        backgroundColor: '#FDFEFE'
    },
}));

function BlockerXHomepageAnalysis() {
    const [selectedComponent, setSelectedComponent] = useState(null);

    const handleComponentSelect = (component) => {
        setSelectedComponent(component);
    };

    return (
        <div>
            {selectedComponent ? (
                <>
                    <div onClick={() => {
                        setSelectedComponent(null)
                    }} className='text-left px-16 pt-8 text-2xl capitalize cursor-pointer'> <ArrowBack /> Home</div>
                    <div>{selectedComponent}</div>
                </>
            ) : (
                <>
                    <Typography
                        variant="h4"
                        gutterBottom
                        sx={{ pt: 4, textAlign: 'center', fontWeight: 'bolder' }}
                    >
                        BlockerX Analysis
                    </Typography>
                    <Grid container spacing={3} sx={{ p: 4 }}>
                        {items.map((item, index) => (
                            <Grid item xs={12} sm={6} md={3} key={index}>
                                <StyledPaper
                                    sx={{ cursor: 'pointer' }}
                                    elevation={3}
                                    onClick={() => handleComponentSelect(item.component)}
                                >
                                    <Typography variant="h6">
                                        {item.name}
                                        <span className='pl-4'>
                                            {item.icon}
                                        </span>
                                    </Typography>
                                </StyledPaper>
                            </Grid>
                        ))}
                    </Grid>
                </>

            )}
        </div>
    );
}

export default BlockerXHomepageAnalysis;
