import React, { useEffect, useState } from 'react';
import {
    Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Fab, Modal, Box, TextField, MenuItem, Button, IconButton
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import axios from 'axios';
import { ZENZE_AUTH_TOKEN } from '../../../api/Tokens';
import { ZENZE_BASE_URL } from '../../../api/Constants';

const ZenzeAppChallenges = () => {
    const [challenges, setChallenges] = useState([]);
    const [openModal, setOpenModal] = useState(false);
    const [newChallenge, setNewChallenge] = useState({
        appName: '',
        packageName: '',
        startTime: null,
        endTime: null,
        name: '',
        usage: 10,
        blockingLimit: 4,
    });

    // Famous apps and corresponding package names
    const appOptions = [
        { appName: 'Facebook', packageName: 'com.facebook.katana' },
        { appName: 'Instagram', packageName: 'com.instagram.android' },
        { appName: 'Twitter', packageName: 'com.twitter.android' },
        { appName: 'TikTok', packageName: 'com.zhiliaoapp.musically' },
        { appName: 'YouTube', packageName: 'com.google.android.youtube' },
    ];

    useEffect(() => {
        // Fetch challenges data
        axios.get(`${ZENZE_BASE_URL}/api/v2/challenges`, {
            headers: {
                token: ZENZE_AUTH_TOKEN,
            },
        })
            .then((response) => {
                setChallenges(response.data.data.challenges);
            })
            .catch((error) => console.error('Error fetching challenges:', error));
    }, []);

    const handleOpenModal = () => setOpenModal(true);
    const handleCloseModal = () => setOpenModal(false);

    const handleFieldChange = (e) => {
        const { name, value } = e.target;
        setNewChallenge({ ...newChallenge, [name]: value });
    };

    const handleAppChange = (e) => {
        const selectedApp = appOptions.find(app => app.appName === e.target.value);
        setNewChallenge({
            ...newChallenge,
            appName: selectedApp.appName,
            packageName: selectedApp.packageName
        });
    };

    const handleDateChange = (name, value) => {
        setNewChallenge({ ...newChallenge, [name]: value });
    };

    const handleSubmit = () => {
        const challengeData = {
            ...newChallenge,
            type: 'app_detox',
            usage: Number(newChallenge.usage),
            blockingLimit: Number(newChallenge.blockingLimit),
            startTime: new Date(newChallenge.startTime).getTime(),
            endTime: new Date(newChallenge.endTime).getTime(),
        };

        axios.post(`${ZENZE_BASE_URL}/api/v1/challenges`, challengeData, {
            headers: {
                token: ZENZE_AUTH_TOKEN,
            },
        })
            .then(() => {
                setChallenges([...challenges, challengeData]);
                handleCloseModal();
            })
            .catch((error) => console.error('Error creating challenge:', error));
    };

    const handleDelete = (challengeId) => {
        axios.delete(`${ZENZE_BASE_URL}/api/v1/challenges/${challengeId}`, {
            headers: {
                token: ZENZE_AUTH_TOKEN,
            },
        })
            .then(() => {
                setChallenges(challenges.filter(challenge => challenge._id !== challengeId));
            })
            .catch((error) => console.error('Error deleting challenge:', error));
    };

    return (
        <div>
            <TableContainer component={Paper} sx={{ m: 2 }}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>App Name</TableCell>
                            <TableCell>Challenge Name</TableCell>
                            <TableCell>Start Time</TableCell>
                            <TableCell>End Time</TableCell>
                            <TableCell>Usage</TableCell>
                            <TableCell>Blocking Limit</TableCell>
                            <TableCell>Participants</TableCell>
                            <TableCell>Actions</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {challenges.map((challenge) => (
                            <TableRow key={challenge._id}>
                                <TableCell>{challenge.appName}</TableCell>
                                <TableCell>{challenge.name}</TableCell>
                                <TableCell>{new Date(challenge.startTime).toLocaleString()}</TableCell>
                                <TableCell>{new Date(challenge.endTime).toLocaleString()}</TableCell>
                                <TableCell>{challenge.usage}</TableCell>
                                <TableCell>{challenge.blockingLimit}</TableCell>
                                <TableCell>{challenge.participants}</TableCell>
                                <TableCell>
                                    <IconButton color="error" onClick={() => handleDelete(challenge._id)}>
                                        <DeleteIcon />
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

            {/* FAB for Adding New Challenge */}
            <Fab color="primary" aria-label="add" style={{ position: 'fixed', bottom: 16, right: 16 }} onClick={handleOpenModal}>
                <AddIcon />
            </Fab>

            {/* Modal for Adding New Challenge */}
            <Modal open={openModal} onClose={handleCloseModal}>
                <Box sx={{ p: 4, bgcolor: 'background.paper', borderRadius: 2, width: 400, margin: 'auto', mt: '50px' }}>
                    <h2>Add New Challenge</h2>
                    <TextField
                        fullWidth
                        select
                        label="App Name"
                        name="appName"
                        value={newChallenge.appName}
                        onChange={handleAppChange}
                        margin="normal"
                    >
                        {appOptions.map((option) => (
                            <MenuItem key={option.appName} value={option.appName}>
                                {option.appName}
                            </MenuItem>
                        ))}
                    </TextField>
                    <TextField
                        fullWidth
                        label="Package Name"
                        name="packageName"
                        value={newChallenge.packageName}
                        margin="normal"
                        disabled
                    />
                    <TextField
                        fullWidth
                        label="Challenge Name"
                        name="name"
                        value={newChallenge.name}
                        onChange={handleFieldChange}
                        margin="normal"
                    />
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DateTimePicker
                            label="Start Time"
                            value={newChallenge.startTime}
                            onChange={(value) => handleDateChange('startTime', value)}
                            renderInput={(props) => <TextField {...props} fullWidth margin="normal" />}
                        />
                        <DateTimePicker
                            label="End Time"
                            value={newChallenge.endTime}
                            onChange={(value) => handleDateChange('endTime', value)}
                            renderInput={(props) => <TextField {...props} fullWidth margin="normal" />}
                        />
                    </LocalizationProvider>
                    <TextField
                        fullWidth
                        label="Usage"
                        name="usage"
                        type="number"
                        value={newChallenge.usage}
                        onChange={handleFieldChange}
                        margin="normal"
                    />
                    <TextField
                        fullWidth
                        label="Blocking Limit"
                        name="blockingLimit"
                        type="number"
                        value={newChallenge.blockingLimit}
                        onChange={handleFieldChange}
                        margin="normal"
                    />
                    <Button variant="contained" color="primary" fullWidth onClick={handleSubmit}>
                        Create Challenge
                    </Button>
                </Box>
            </Modal>
        </div>
    );
};

export default ZenzeAppChallenges;
