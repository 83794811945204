import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { BASE_URL, ZENZE_BASE_URL } from '../../../api/Constants';
import {
    Box,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Typography
} from '@mui/material';
import { showTimeInAgoFormat } from '../../../Utils/time';

function ZenzeRecentRooms() {
    const [rooms, setRooms] = useState([]);

    useEffect(() => {
        fetchRooms();
    }, []);

    const fetchRooms = async () => {
        try {
            const response = await axios.get(`${ZENZE_BASE_URL}/api/stats/v1/recent-rooms`);
            setRooms(response.data.data.rooms);
        } catch (error) {
            console.error('Error fetching rooms:', error);
        }
    };

    return (
        <Box sx={{ p: 8 }}>
            <Typography variant="h4" gutterBottom>
                Showing recent {rooms.length > 0 ? rooms.length : ''} zenze rooms
            </Typography>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Room ID</TableCell>
                            <TableCell>Room Creator</TableCell>
                            <TableCell>Email</TableCell>
                            <TableCell>Users Count</TableCell>
                            <TableCell>Users</TableCell>
                            <TableCell>Created At</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rooms.map(room => (
                            <TableRow key={room.roomId}>
                                <TableCell>{room.roomId}</TableCell>
                                <TableCell>{room.username || 'No username'}</TableCell>
                                <TableCell>{room.email || 'anonymous'}</TableCell>
                                <TableCell>{room.userCount}</TableCell>
                                <TableCell>
                                    {/* Display other users if present */}
                                    {room.otherUsersData && room.otherUsersData.length > 0 ? (
                                        <ul>
                                            {room.otherUsersData.map((otherUser, index) => (
                                                <li key={index}>
                                                    {otherUser.username || 'No username'} - {otherUser.email || 'No email'}
                                                </li>
                                            ))}
                                        </ul>
                                    ) : (
                                        'No other users'
                                    )}
                                </TableCell>
                                <TableCell>{showTimeInAgoFormat(room.createdAt)}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    );
}

export default ZenzeRecentRooms;
