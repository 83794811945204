import React, { useState, useEffect } from 'react';
import { TextField, Button, Box, Typography } from '@mui/material';
import axios from 'axios';
import { BASE_URL } from '../../../api/Constants';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { showTimeInAgoFormat } from '../../../Utils/time';

function ModifyReplies() {
    const [messages, setMessages] = useState([]);
    const [editMode, setEditMode] = useState({});
    const [updatedMessages, setUpdatedMessages] = useState({});

    // Fetch messages on component mount
    useEffect(() => {
        fetchMessages();
    }, []);

    // Fetch messages from the API
    const fetchMessages = async () => {
        try {
            const response = await axios.get(`${BASE_URL}/api/v1/ticket-ops/replies`);
            if (response.data.status === 200) {
                setMessages(response.data.data);
                toast.success('Messages fetched successfully!');
            }
        } catch (error) {
            console.error('Error fetching messages:', error);
            toast.error('Failed to fetch messages.');
        }
    };

    // Handle edit mode toggle
    const handleEditToggle = (id) => {
        setEditMode((prev) => ({
            ...prev,
            [id]: !prev[id],
        }));
    };

    // Handle message change
    const handleChange = (id, value) => {
        setUpdatedMessages((prev) => ({
            ...prev,
            [id]: value,
        }));
    };

    // Save updated message to the API
    const saveMessage = async (id) => {
        try {
            const updatedMessage = updatedMessages[id];
            await axios.put(`${BASE_URL}/api/v1/ticket-ops/replies/${id}`, { message: updatedMessage });
            setEditMode((prev) => ({
                ...prev,
                [id]: false,
            }));
            fetchMessages(); // Refresh messages after update
            toast.success('Message updated successfully!');
        } catch (error) {
            console.error('Error updating message:', error);
            toast.error('Failed to update the message.');
        }
    };

    return (
        <Box sx={{ p: 4 }}>
            <ToastContainer />
            <Typography variant="h4" gutterBottom>
                Modify Replies
            </Typography>
            {messages.map((msg) => (
                <Box key={msg._id} sx={{ mb: 3 }}>
                    <Typography variant="subtitle1" sx={{ mb: 1 }}>
                        Ticket ID: {msg.ticketId} | Sent: {showTimeInAgoFormat(msg.time)}
                    </Typography>
                    <TextField
                        fullWidth
                        multiline
                        variant="outlined"
                        value={editMode[msg._id] ? updatedMessages[msg._id] || msg.message : msg.message}
                        onChange={(e) => handleChange(msg._id, e.target.value)}
                        disabled={!editMode[msg._id]}
                    />
                    <Box sx={{ mt: 1 }}>
                        {editMode[msg._id] ? (
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={() => saveMessage(msg._id)}
                                sx={{ mr: 1 }}
                            >
                                Save
                            </Button>
                        ) : (
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={() => handleEditToggle(msg._id)}
                                sx={{ mr: 1 }}
                            >
                                Edit
                            </Button>
                        )}
                        {editMode[msg._id] && (
                            <Button
                                variant="outlined"
                                color="error"
                                onClick={() => handleEditToggle(msg._id)}
                            >
                                Cancel
                            </Button>
                        )}
                    </Box>
                </Box>
            ))}
        </Box>
    );
}

export default ModifyReplies;
