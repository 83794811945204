import secureLocalStorage from 'react-secure-storage'
import React, { useState, useEffect } from 'react'
import Button from '@mui/material/Button'
import MuiAlert from '@mui/material/Alert'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Snackbar from '@mui/material/Snackbar'
import { Input, Switch } from '@mui/material'
import Stack from '@mui/material/Stack'
import { styled } from '@mui/material/styles'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell, { tableCellClasses } from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import ReactGA from 'react-ga'
import { useNavigate } from 'react-router-dom'
import { WorkspacePremium } from '@mui/icons-material'
import { convertMillisecondsToDate, showTimeInAgoFormat } from '../../../Utils/time'

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.primary.dark,
        color: theme.palette.common.white,
        fontSize: 20
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 16
    }
}))

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover
    },
    '&:last-child td, &:last-child th': {
        border: 0
    }
}))

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant='filled' {...props} />
})

const Userinfo = () => {
    return <UserPost />
}

const UserPost = () => {
    let navigate = useNavigate()

    const [uid, setUid] = useState('')

    const token = secureLocalStorage.getItem('token')
    const dashboardUid = secureLocalStorage.getItem('uid')
    const [userEmail, setUserEmail] = useState('')

    // User Info
    const [username, setUsername] = useState('')
    const [email, setEmail] = useState('')
    const [partner, setPartner] = useState('')
    const [lastActive, setLastActive] = useState(123456789)
    const [device, setDevice] = useState('')
    const [iosDevice, setIosDevice] = useState('')
    const [appVersion, setAppVersion] = useState('')
    const [country, setCountry] = useState('')
    const [language, setLanguage] = useState('')
    const [installedAt, setInstalledAt] = useState(123456789)
    const [chromeUser, setChromeUser] = useState(false)
    const [forceUninstall, setForceUninstall] = useState(false)
    const [installationSource, setInstallationSource] = useState('')
    const [orderId, setOrderId] = useState('')
    const [premiumStatus, setPremiumStatus] = useState('Inactive')
    const [planName, setPlanName] = useState('')
    const [purchaseToken, setPurchaseToken] = useState('')
    const [paymentMethod, setPaymentMethod] = useState('')
    const [showOtherAccountsButton, setShowOtherAccountsButton] = useState(false)

    const [successMessage, setSuccessMessage] = useState('Success')
    const [errorMessage, setErrorMessage] = useState('Error')

    // Success and Error alerts
    const [openSuccessAlert, setOpenSuccessAlert] = useState(false)
    const closeSuccessAlert = (event, reason) => {
        if (reason === 'clickaway') {
            return
        }
        setOpenSuccessAlert(false)
    }
    const [openErrorAlert, setOpenErrorAlert] = useState(false)
    const closeErrorAlert = (event, reason) => {
        if (reason === 'clickaway') {
            return
        }
        setOpenErrorAlert(false)
    }

    const handlePreventUninstallStatus = () => {
        setForceUninstall(!forceUninstall)
        changePreventUninstallStatusOfUser(uid, !forceUninstall)
    }

    const changePreventUninstallStatusOfUser = (uid, status) => {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', authorization: `Bearer ${token}` },
            body: JSON.stringify({ uid: uid, status: status })
        }
        fetch('https://dev.blockerx.net/api/v1/forceUninstall', requestOptions)
            .then((response) => response.json())
            .then((data) => {
                if (data.status === 'success') {
                    setSuccessMessage('Force Prevent Uninstall has been modified')
                    setOpenSuccessAlert(true)
                    return
                } else if (data.status === 401) {
                    secureLocalStorage.clear()
                    navigate('/tokenExpired')
                } else if (data.status === 401) {
                    secureLocalStorage.clear()
                    navigate('/tokenExpired')
                } else if (data.status === 403) {
                    secureLocalStorage.clear()
                    navigate('/forbidden')
                } else {
                    secureLocalStorage.clear()
                    navigate('/tokenExpired')
                }
            })
            .catch((error) => {})
    }

    const setUserInformation = (USER) => {
        setUsername(USER.username)
        setEmail(USER.email)
        setPartner(USER.partner)
        setLastActive(USER.lastActive)
        setChromeUser(USER.chrome)
        setDevice(USER.device)
        setIosDevice(USER.iosDevice)
        setAppVersion(USER.appVersion)
        setInstalledAt(USER.installedAt)
        setForceUninstall(USER.forceUninstall)
        setPremiumStatus(USER.premium.status)
        setOrderId(USER.premium.orderId)
        setPlanName(USER.premium.planName)
        setPurchaseToken(USER.premium.purchaseToken)
        setPaymentMethod(USER.premium.paymentMethod)

        if (USER.country === 'Not present') {
            setCountry('US')
        } else {
            setCountry(USER.country)
        }

        if (USER.language === 'Not present') {
            setLanguage('en')
        } else {
            setLanguage(USER.language)
        }

        if (USER.installationSource === 'playStore') {
            setInstallationSource('Google Play Store')
        } else if (USER.installationSource === 'blockerxWeb') {
            setInstallationSource('APK')
        } else {
            setInstallationSource('Unknown Source')
        }
    }

    const UserInfoHandler = (UID) => {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', authorization: `Bearer ${token}` },
            body: JSON.stringify({ uid: UID, dashboardUid: dashboardUid })
        }
        fetch('https://dev.blockerx.net/api/dashboard/userinfo', requestOptions)
            .then((response) => response.json())
            .then((data) => {
                if (data.status === 200) {
                    setUserInformation(data.data.user)
                    return
                } else if (data.status === 401) {
                    secureLocalStorage.clear()
                    navigate('/tokenExpired')
                } else if (data.status === 401) {
                    secureLocalStorage.clear()
                    navigate('/tokenExpired')
                } else if (data.status === 403) {
                    secureLocalStorage.clear()
                    navigate('/forbidden')
                } else {
                    secureLocalStorage.clear()
                    navigate('/tokenExpired')
                }
            })
            .catch((error) => {})
    }

    const isValidEmail = (email) => {
        const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/
        return emailPattern.test(email)
    }

    const allUserInfo = () => {
        navigate(`/userinfo/zenze/${userEmail}`)
    }

    const getUserInfo = () => {
        if (userEmail.trim() === '') {
            setErrorMessage('User identifier should not be empty')
            setOpenErrorAlert('true')
            setUserEmail('')
            return
        }

        document.title = `User - ${userEmail}`

        navigate('/userinfo/' + userEmail)

        if (isValidEmail(userEmail)) {
            getFirebaseUserInfoHandler()
        } else if (userEmail.length < 10) {
            setErrorMessage('UID should be valid')
            setOpenErrorAlert('true')
            setUserEmail('')
        } else {
            setUid(userEmail)
            UserInfoHandler(userEmail)
        }
    }

    const getFirebaseUserInfoHandler = () => {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', authorization: `Bearer ${token}` },
            body: JSON.stringify({ email: userEmail, dashboardUid: secureLocalStorage.getItem('uid') })
        }

        fetch('https://healify.blockerx.net/api/dashboard/firebase/userInfo', requestOptions)
            .then((response) => response.json())
            .then((data) => {
                if (data.status === 'success') {
                    setUid(data.data.uid)
                    UserInfoHandler(data.data.uid)
                } else if (data.status === 'invalid_token') {
                    secureLocalStorage.clear()
                    navigate('/tokenExpired')
                } else {
                    setErrorMessage('User not found!')
                    setOpenErrorAlert(true)
                    setShowOtherAccountsButton(true)
                }
            })
    }

    const getLanguageOfUser = () => {
        try {
            const languageNames = new Intl.DisplayNames(['en'], {
                type: 'language'
            })
            return languageNames.of(language)
        } catch {
            return 'English'
        }
    }

    const getCountryOfUser = () => {
        try {
            const regionNames = new Intl.DisplayNames(['en'], { type: 'region' })
            return regionNames.of(country)
        } catch {
            return 'USA'
        }
    }

    const getBooleanString = (value) => {
        if (value) {
            return 'Yes'
        }
        return 'No'
    }

    const handleEnterKey = (event) => {
        if (event.key === 'Enter') {
            // Call function to fetch user info
            getUserInfo()
        }
    }

    useEffect(() => {
        ReactGA.pageview(window.location.pathname)
    }, [uid])

    return (
        <Box sx={{ flexGrow: 2 }}>
            <h1 className='header-top mt-5'>Get User Information</h1>
            <Grid align='center' justify='center' spacing={2} sx={{ marginBottom: '100px' }}>
                <Grid item lg={4} md={6} sm={12} className='pt-5'>
                    <Input
                        disablePortal
                        placeholder='Email / User ID'
                        id='combo-box-demo'
                        sx={{ width: '400px' }}
                        onChange={(event, item) => {
                            setUserEmail(event.target.value)
                        }}
                        onKeyDown={handleEnterKey}
                    />
                </Grid>

                <Grid item lg={4} md={6} sm={12} className='pt-5'>
                    <Button className='h-12' sx={{ width: '400px' }} onClick={getUserInfo} color='info' variant='contained'>
                        Get Info
                    </Button>
                </Grid>

                {showOtherAccountsButton && (
                    <Grid item lg={4} md={6} sm={12} className='pt-5'>
                        <Button className='h-12' sx={{ width: '400px', backgroundColor: '#42977e' }} onClick={allUserInfo} variant='contained'>
                            Search for Zenze
                        </Button>
                    </Grid>
                )}

                {uid.length > 0 && (
                    <Grid>
                        <TableContainer>
                            <Table sx={{ width: '70%', marginTop: 10 }} aria-label='customized table'>
                                <TableHead>
                                    <TableRow>
                                        <StyledTableCell>User ID</StyledTableCell>
                                        <StyledTableCell align='left'>{uid}</StyledTableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <StyledTableRow key='uid'>
                                        <StyledTableCell component='th' scope='row'>
                                            Premium
                                        </StyledTableCell>
                                        <StyledTableCell align='left'>
                                            {premiumStatus}
                                            {premiumStatus === 'Active' && <WorkspacePremium sx={{ marginLeft: '15px', color: '#efb73e', fontSize: '25px' }} />}
                                        </StyledTableCell>
                                    </StyledTableRow>
                                    <StyledTableRow key='username'>
                                        <StyledTableCell component='th' scope='row'>
                                            Username
                                        </StyledTableCell>
                                        <StyledTableCell align='left'>{username}</StyledTableCell>
                                    </StyledTableRow>
                                    <StyledTableRow key='username'>
                                        <StyledTableCell component='th' scope='row'>
                                            Email
                                        </StyledTableCell>
                                        <StyledTableCell align='left'>{email}</StyledTableCell>
                                    </StyledTableRow>
                                    <StyledTableRow key='username'>
                                        <StyledTableCell component='th' scope='row'>
                                            Partner
                                        </StyledTableCell>
                                        <StyledTableCell align='left'>{partner}</StyledTableCell>
                                    </StyledTableRow>
                                    <StyledTableRow key='uid'>
                                        <StyledTableCell component='th' scope='row'>
                                            Plan Name
                                        </StyledTableCell>
                                        <StyledTableCell align='left'>{planName}</StyledTableCell>
                                    </StyledTableRow>
                                    <StyledTableRow key='uid'>
                                        <StyledTableCell component='th' scope='row'>
                                            Order ID
                                        </StyledTableCell>
                                        <StyledTableCell align='left'>{orderId}</StyledTableCell>
                                    </StyledTableRow>
                                    <StyledTableRow key='uid'>
                                        <StyledTableCell component='th' scope='row'>
                                            Purchase Token
                                        </StyledTableCell>
                                        <StyledTableCell align='left'>{purchaseToken}</StyledTableCell>
                                    </StyledTableRow>
                                    <StyledTableRow key='uid'>
                                        <StyledTableCell component='th' scope='row'>
                                            Payment Method
                                        </StyledTableCell>
                                        <StyledTableCell align='left'>{paymentMethod}</StyledTableCell>
                                    </StyledTableRow>
                                    <StyledTableRow key='username'>
                                        <StyledTableCell component='th' scope='row'>
                                            Last Active
                                        </StyledTableCell>
                                        <StyledTableCell align='left'>
                                            {showTimeInAgoFormat(lastActive)} on {convertMillisecondsToDate(lastActive)}
                                        </StyledTableCell>
                                    </StyledTableRow>
                                    <StyledTableRow key='username'>
                                        <StyledTableCell component='th' scope='row'>
                                            Android Device
                                        </StyledTableCell>
                                        <StyledTableCell align='left'>{device}</StyledTableCell>
                                    </StyledTableRow>
                                    <StyledTableRow key='username2222'>
                                        <StyledTableCell component='th' scope='row'>
                                            iOS Device
                                        </StyledTableCell>
                                        <StyledTableCell align='left'>{iosDevice}</StyledTableCell>
                                    </StyledTableRow>
                                    <StyledTableRow key='username'>
                                        <StyledTableCell component='th' scope='row'>
                                            App Version
                                        </StyledTableCell>
                                        <StyledTableCell align='left'>{appVersion}</StyledTableCell>
                                    </StyledTableRow>
                                    <StyledTableRow key='username'>
                                        <StyledTableCell component='th' scope='row'>
                                            Country
                                        </StyledTableCell>
                                        <StyledTableCell align='left'>{getCountryOfUser()}</StyledTableCell>
                                    </StyledTableRow>
                                    <StyledTableRow key='username'>
                                        <StyledTableCell component='th' scope='row'>
                                            Language
                                        </StyledTableCell>
                                        <StyledTableCell align='left'>{getLanguageOfUser()}</StyledTableCell>
                                    </StyledTableRow>
                                    <StyledTableRow key='username'>
                                        <StyledTableCell component='th' scope='row'>
                                            Installed At
                                        </StyledTableCell>
                                        <StyledTableCell align='left'>{convertMillisecondsToDate(installedAt)}</StyledTableCell>
                                    </StyledTableRow>
                                    <StyledTableRow key='uid'>
                                        <StyledTableCell component='th' scope='row'>
                                            Force Prevent Uninstall
                                        </StyledTableCell>
                                        <StyledTableCell align='left'>
                                            <Switch checked={forceUninstall} onChange={handlePreventUninstallStatus} inputProps={{ 'aria-label': 'controlled' }} />
                                        </StyledTableCell>
                                    </StyledTableRow>
                                    <StyledTableRow key='username'>
                                        <StyledTableCell component='th' scope='row'>
                                            Installation Source
                                        </StyledTableCell>
                                        <StyledTableCell align='left'>{installationSource}</StyledTableCell>
                                    </StyledTableRow>
                                    <StyledTableRow key='username'>
                                        <StyledTableCell component='th' scope='row'>
                                            Chrome Extension User
                                        </StyledTableCell>
                                        <StyledTableCell align='left'>{getBooleanString(chromeUser)}</StyledTableCell>
                                    </StyledTableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                )}
                <Stack spacing={2} sx={{ width: '100%' }}>
                    <Snackbar open={openSuccessAlert} autoHideDuration={2000} onClose={closeSuccessAlert}>
                        <Alert onClose={closeSuccessAlert} severity='success' sx={{ width: '100%' }}>
                            {successMessage}
                        </Alert>
                    </Snackbar>
                </Stack>

                <Stack spacing={2} sx={{ width: '100%' }}>
                    <Snackbar open={openErrorAlert} autoHideDuration={2000} onClose={closeErrorAlert}>
                        <Alert onClose={closeErrorAlert} severity='error' sx={{ width: '100%' }}>
                            {errorMessage}
                        </Alert>
                    </Snackbar>
                </Stack>
            </Grid>
        </Box>
    )
}

export default Userinfo
