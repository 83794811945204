import secureLocalStorage from 'react-secure-storage'
import './TicketFeedback.css'
import React, { useEffect, useState } from 'react'
import { BarChart } from '@mui/x-charts/BarChart'
import { Button, TextField } from '@mui/material'
import { styled } from '@mui/material/styles'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell, { tableCellClasses } from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import { formatDateToYYYYMMDD, showTimeInAgoFormat } from '../../../Utils/time'
import { capitalizeFirstLetter } from '../../../Utils/string'
import { Star } from '@mui/icons-material'

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: '#4F709C',
        color: theme.palette.common.white,
        fontSize: 16
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14
    }
}))

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0
    }
}))

const AgentTable = ({ agentsData }) => {
    // Calculate average rating for each agent
    const agentRatings = Object.entries(
        agentsData
            .filter(({ agent }) => agent !== 'agent')
            .reduce((acc, { agent, stars, count }) => {
                if (!isNaN(stars) && !isNaN(count)) {
                    if (!acc[agent]) {
                        acc[agent] = { totalStars: 0, totalCount: 0 }
                    }
                    acc[agent].totalStars += stars * count
                    acc[agent].totalCount += count
                }
                return acc
            }, {})
    )
        .map(([agent, { totalStars, totalCount }]) => ({ agent, averageRating: totalStars / totalCount }))
        .sort((a, b) => b.averageRating - a.averageRating)

    return (
        <div className='container mx-auto'>
            <p className='text-center text-4xl font-bold mt-12 mb-6'>Average Agent Ratings</p>
            <TableContainer component={Paper} className=''>
                <Table aria-label='customized table'>
                    <TableHead>
                        <TableRow>
                            <StyledTableCell>Agent</StyledTableCell>
                            <StyledTableCell align='center'>Average Rating</StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {agentRatings.map(({ agent, averageRating }) => (
                            <StyledTableRow key={Math.random()}>
                                <StyledTableCell align='center'>{capitalizeFirstLetter(agent)}</StyledTableCell>
                                <StyledTableCell align='center'>{averageRating.toFixed(2)}</StyledTableCell>
                            </StyledTableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    )
}

function TicketFeedback() {
    const [apiCalled, setApiCalled] = useState(false)
    const [oneStars, setOneStars] = useState(0)
    const [twoStars, setTwoStars] = useState(0)
    const [threeStars, setThreeStars] = useState(0)
    const [fourStars, setFourStars] = useState(0)
    const [fiveStars, setFiveStars] = useState(0)
    const [agentAverageData, setAgentAverageData] = useState([])

    const [campaignsList, setCampaignsList] = useState([])

    const [alexArray, setAlexArray] = useState([0, 0, 0, 0, 0])
    const [simonArray, setSimonArray] = useState([0, 0, 0, 0, 0])
    const [tedArray, setTedArray] = useState([0, 0, 0, 0, 0])
    const [naomiArray, setNaomiArray] = useState([0, 0, 0, 0, 0])
    const [henryArray, setHenryArray] = useState([0, 0, 0, 0, 0])
    const [ashleyArray, setAshleyArray] = useState([0, 0, 0, 0, 0])
    const [millieArray, setMillieArray] = useState([0, 0, 0, 0, 0])

    const [currentAgentName, setCurrentAgentName] = useState('All Agents')
    const [currentAgentId, setCurrentAgentId] = useState('all')
    const [startDate, setStartDate] = useState(1695651200000)
    const [startDateInputValue, setStartDateInputValue] = useState('2023-09-23')
    const [endDate, setEndDate] = useState(new Date().getTime())
    const [endDateInputValue, setEndDateInputValue] = useState(formatDateToYYYYMMDD(new Date().getTime()))
    const dashboardUid = secureLocalStorage.getItem('uid')

    const handleStars = (starsArr) => {
        starsArr.forEach((eachStar) => {
            if (eachStar.stars === 1) {
                setOneStars(eachStar.count)
            } else if (eachStar.stars === 2) {
                setTwoStars(eachStar.count)
            } else if (eachStar.stars === 3) {
                setThreeStars(eachStar.count)
            } else if (eachStar.stars === 4) {
                setFourStars(eachStar.count)
            } else {
                setFiveStars(eachStar.count)
            }
        })
    }

    const resetStarsCount = () => {
        setOneStars(0)
        setTwoStars(0)
        setThreeStars(0)
        setFourStars(0)
        setFiveStars(0)
    }

    const processFeedbackData = (data, agentName) => {
        const agentData = data.filter((entry) => entry.agent === agentName)
        const resultArray = Array.from({ length: 5 }, (_, index) => {
            const feedbackEntry = agentData.find((entry) => entry.stars === index + 1)
            return {
                stars: index + 1,
                count: feedbackEntry ? feedbackEntry.count : 0
            }
        })

        const countsArray = Array.from({ length: 5 }, (_, index) => {
            const feedbackEntry = resultArray.find((entry) => entry.stars === index + 1)
            return feedbackEntry ? feedbackEntry.count : 0
        })

        return countsArray
    }

    const getFeedbackRatingStats = () => {
        resetStarsCount()
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                startDate: startDate,
                endDate: endDate,
                agent: currentAgentId,
                dashboardUid
            })
        }

        fetch(`https://dev.blockerx.net/api/dashboard/ticket/stats`, requestOptions)
            .then((response) => response.json())
            .then((data) => {
                console.log('Data : ', data)
                if (data.status === 200) {
                    setApiCalled(true)
                    handleStars(data.data.ratings)
                    setAgentAverageData(data.data.ratings)
                    setCampaignsList(data.data.latestRatings)
                    setAlexArray(processFeedbackData(data.data.ratings, 'alex'))
                    setSimonArray(processFeedbackData(data.data.ratings, 'simon'))
                    setTedArray(processFeedbackData(data.data.ratings, 'ted'))
                    setNaomiArray(processFeedbackData(data.data.ratings, 'naomi'))
                    setHenryArray(processFeedbackData(data.data.ratings, 'henry'))
                    setAshleyArray(processFeedbackData(data.data.ratings, 'ashley'))
                    setMillieArray(processFeedbackData(data.data.ratings, 'millie'))
                } else {
                }
            })
            .catch((error) => {
                console.log('Error : ', error)
            })
    }

    const openEachTicketContainer = (ticketId) => {
        window.open(`/customer-tickets/${ticketId}`, '_blank')
    }

    useEffect(() => {
        if (!apiCalled) {
            getFeedbackRatingStats()
        }
    })
    return (
        <>
            <div>
                <p className='text-center text-4xl font-bold mt-12'>Customer Support Feedback - {currentAgentName}</p>
            </div>
            <div className='ticketfeedback__data__container'>
                <TextField
                    label='Start Date'
                    type='date'
                    value={startDateInputValue}
                    InputLabelProps={{ shrink: true }}
                    sx={{ width: '14%', margin: '1%' }}
                    onChange={(event, item) => {
                        setStartDateInputValue(event.target.value)
                        const myDate = event.target.value.split('-')
                        const newDate = new Date(myDate[0], myDate[1] - 1, myDate[2]).getTime()
                        console.log('Start Date : ', newDate)
                        setStartDate(newDate)
                    }}
                />

                <TextField
                    label='End Date'
                    id='combo-box-demo'
                    type='date'
                    value={endDateInputValue}
                    InputLabelProps={{ shrink: true }}
                    sx={{ width: '14%', margin: '1%' }}
                    onChange={(event, item) => {
                        setEndDateInputValue(event.target.value)
                        const myDate = event.target.value.split('-')
                        const newDate = new Date(myDate[0], myDate[1] - 1, myDate[2]).getTime()
                        console.log('End Date : ', newDate)
                        setEndDate(newDate + 86400000)
                    }}
                />

                <Button className='h-12' sx={{ width: '10%' }} onClick={() => getFeedbackRatingStats()} variant='contained'>
                    Apply
                </Button>
            </div>
            <div className='ticketfeedback__container'>
                <BarChart
                    xAxis={[
                        {
                            id: 'barCategories',
                            data: ['1 Star', '2 Stars', '3 Stars', '4 Stars', '5 Stars'],
                            scaleType: 'band'
                        }
                    ]}
                    series={[
                        {
                            data: alexArray,
                            label: 'Alex'
                        },
                        {
                            data: simonArray,
                            label: 'Simon'
                        },
                        {
                            data: tedArray,
                            label: 'Ted',
                            color: 'orange'
                        },
                        {
                            data: naomiArray,
                            label: 'Naomi',
                            color: 'green'
                        },
                        {
                            data: henryArray,
                            label: 'Henry'
                        },
                        {
                            data: ashleyArray,
                            label: 'Ashley'
                        },
                        {
                            data: millieArray,
                            label: 'Millie',
                            color: 'magenta'
                        }
                    ]}
                    width={800}
                    height={500}
                />
            </div>
            {agentAverageData.length > 0 && <AgentTable agentsData={agentAverageData} />}
            <div>
                <p className='text-center text-4xl font-bold mt-12 mb-6'>Recent Ratings</p>
            </div>
            <div className='campaign__list__container__feedback pb-48'>
                <TableContainer component={Paper} className=''>
                    <Table aria-label='customized table'>
                        <TableHead>
                            <TableRow>
                                <StyledTableCell>Ticket ID</StyledTableCell>
                                <StyledTableCell align='center'>Agent</StyledTableCell>
                                <StyledTableCell align='center'>Date</StyledTableCell>
                                <StyledTableCell align='center'>Stars</StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {campaignsList.map((row) => (
                                <StyledTableRow key={row._id}>
                                    <StyledTableCell className='cursor-pointer hover:underline' scope='row' onClick={() => openEachTicketContainer(row.id)}>
                                        {row.id}
                                    </StyledTableCell>
                                    <StyledTableCell align='center'>{capitalizeFirstLetter(row.agent)}</StyledTableCell>
                                    <StyledTableCell align='center'> {showTimeInAgoFormat(Number(row.time))}</StyledTableCell>
                                    <StyledTableCell align='center'>
                                        {Array.from({ length: row.rating }).map((_, index) => (
                                            <Star key={index} style={{ color: '#504e46' }} />
                                        ))}
                                    </StyledTableCell>
                                </StyledTableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
        </>
    )
}

export default TicketFeedback
