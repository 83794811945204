import React, { useState } from "react";
import axios from "axios";
import { BASE_URL } from "../../api/Constants";

const AddToBlocklist = () => {
    const [formData, setFormData] = useState({
        uid: "",
        action: "add",
        type: "",
        category: "",
        name: "",
        packageName: "",
    });

    const [errorMessage, setErrorMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState("");

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prev) => ({ ...prev, [name]: value }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setErrorMessage("");
        setSuccessMessage("");
        try {
            const response = await axios.post(`${BASE_URL}/api/v1/wordAction`, formData);
            setSuccessMessage(response.data.message || "Blocklist updated successfully!");
        } catch (error) {
            setErrorMessage(error.response?.data?.message || "An error occurred.");
        }
    };

    return (
        <div className="flex justify-center pt-16">
            <form
                onSubmit={handleSubmit}
                className="bg-white p-6 rounded shadow-md w-full max-w-lg"
            >
                <h2 className="text-2xl font-bold mb-4">Add New item to Blocklist</h2>

                {/* UID */}
                <div className="mb-4">
                    <label className="block mb-2 text-sm font-medium text-gray-700">
                        UID
                    </label>
                    <input
                        type="text"
                        name="uid"
                        value={formData.uid}
                        onChange={handleChange}
                        required
                        className="w-full p-2 border rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
                    />
                </div>

                {/* Action */}
                <div className="mb-4">
                    <label className="block mb-2 text-sm font-medium text-gray-700">
                        Action
                    </label>
                    <select
                        name="action"
                        value={formData.action}
                        onChange={handleChange}
                        className="w-full p-2 border rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
                    >
                        <option value="add">Add</option>
                        <option value="remove">Remove</option>
                    </select>
                </div>

                {/* Type */}
                <div className="mb-4">
                    <label className="block mb-2 text-sm font-medium text-gray-700">
                        Type
                    </label>
                    <select
                        name="type"
                        value={formData.type}
                        onChange={handleChange}
                        className="w-full p-2 border rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
                    >
                        <option value="">Select Type</option>
                        <option value="protected">Protected</option>
                        <option value="block">Block</option>
                        <option value="whitelist">Whitelist</option>
                        <option value="newInstall">New Install</option>
                        <option value="inAppBrowser">In-App Browser</option>
                    </select>
                </div>

                {/* Category */}
                <div className="mb-4">
                    <label className="block mb-2 text-sm font-medium text-gray-700">
                        Category
                    </label>
                    <select
                        name="category"
                        value={formData.category}
                        onChange={handleChange}
                        className="w-full p-2 border rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
                    >
                        <option value="">Select Category</option>
                        <option value="keyword">Keyword</option>
                        <option value="website">Website</option>
                        <option value="app">App</option>
                    </select>
                </div>

                {/* Name */}
                <div className="mb-4">
                    <label className="block mb-2 text-sm font-medium text-gray-700">
                        Name
                    </label>
                    <input
                        type="text"
                        name="name"
                        value={formData.name}
                        onChange={handleChange}
                        required
                        className="w-full p-2 border rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
                    />
                </div>

                {/* Package Name */}
                <div className="mb-4">
                    <label className="block mb-2 text-sm font-medium text-gray-700">
                        Package Name (optional)
                    </label>
                    <input
                        type="text"
                        name="packageName"
                        value={formData.packageName}
                        onChange={handleChange}
                        className="w-full p-2 border rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
                    />
                </div>

                {/* Error or Success Message */}
                {errorMessage && (
                    <p className="text-red-500 text-sm mb-4">{errorMessage}</p>
                )}
                {successMessage && (
                    <p className="text-green-500 text-sm mb-4">{successMessage}</p>
                )}

                <button
                    type="submit"
                    className="w-full bg-blue-500 text-white p-2 rounded hover:bg-blue-600"
                >
                    Submit
                </button>
            </form>
        </div>
    );
};

export default AddToBlocklist;
