import React, { useState } from "react";
import axios from "axios";
import { ZENZE_BASE_URL } from "../../../api/Constants";

const DURATION_OPTIONS = [
  { label: "1 Day", value: 100 },
  { label: "1 Week", value: 700 },
  { label: "1 Month", value: 3000 },
  { label: "2 Months", value: 6000 },
  { label: "6 Months", value: 18000 },
  { label: "1 Year", value: 36000 },
  { label: "2 Years", value: 72000 },
  { label: "5 Years", value: 180000 },
];

const ZenzePremium = () => {
  const [formData, setFormData] = useState({
    uid: "",
    coins: 0,
  });

  const [duration, setDuration] = useState(DURATION_OPTIONS[0].value); // Default to "1 Day"
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleDurationChange = (e) => {
    const selectedValue = parseInt(e.target.value, 10);
    setDuration(selectedValue);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrorMessage("");
    setSuccessMessage("");

    // Update coins based on selected duration
    const updatedFormData = { ...formData, coins: duration };

    console.log("Submitting formData: ", updatedFormData);

    try {
      const response = await axios.post(`${ZENZE_BASE_URL}/api/v2/coins/redeem-premium`, updatedFormData);
      setSuccessMessage(response.data.message || "Premium redeemed successfully!");
    } catch (error) {
      setErrorMessage(error.response?.data?.message || "An error occurred.");
    }
  };

  return (
    <div className="min-h-screen bg-gray-100 flex justify-center items-center">
      <form
        onSubmit={handleSubmit}
        className="bg-white p-6 rounded shadow-md w-full max-w-lg"
      >
        <h2 className="text-2xl font-bold mb-4">Redeem Premium</h2>

        {/* UID */}
        <div className="mb-4">
          <label className="block mb-2 text-sm font-medium text-gray-700">
            UID
          </label>
          <input
            type="text"
            name="uid"
            value={formData.uid}
            onChange={handleChange}
            required
            className="w-full p-2 border rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
        </div>

        {/* Duration Dropdown */}
        <div className="mb-4">
          <label className="block mb-2 text-sm font-medium text-gray-700">
            Duration
          </label>
          <select
            value={duration}
            onChange={handleDurationChange}
            className="w-full p-2 border rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
          >
            {DURATION_OPTIONS.map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </select>
        </div>

        {/* Error or Success Message */}
        {errorMessage && (
          <p className="text-red-500 text-sm mb-4">{errorMessage}</p>
        )}
        {successMessage && (
          <p className="text-green-500 text-sm mb-4">{successMessage}</p>
        )}

        <button
          type="submit"
          className="w-full bg-blue-500 text-white p-2 rounded hover:bg-blue-600"
        >
          Redeem
        </button>
      </form>
    </div>
  );
};

export default ZenzePremium;
